import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$59,998", reinvestedDividends: "--", cashDividends: "--", marketValue: "$107,258", portfolioPercent: "24.9%", totalgainloss: "79%" },
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$7,380", reinvestedDividends: "--", cashDividends: "--", marketValue: "$61,453", portfolioPercent: "14.2%", totalgainloss: "733%" },
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.8%", costBasis: "$51,644", reinvestedDividends: "$8,382", cashDividends: "$360", marketValue: "$60,014", portfolioPercent: "13.9%", totalgainloss: "16%" },
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$40,550", portfolioPercent: "9.4%", totalgainloss: "1%" },        
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$41,343", reinvestedDividends: "--", cashDividends: "--", marketValue: "$31,361", portfolioPercent: "7.3%", totalgainloss: "(24%)" },        
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$12,452", reinvestedDividends: "--", cashDividends: "--", marketValue: "$24,278", portfolioPercent: "5.6%", totalgainloss: "95%" },
        { equities: "Equities Total", ticker: "--", dividendYield: "3.7%", costBasis: "$212,814", reinvestedDividends: "$8,382", cashDividends: "$360", marketValue: "$324,913", portfolioPercent: "75.3%", totalgainloss: "53%" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$106,646", portfolioPercent: "24.7%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "2.5%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$431,559", portfolioPercent: "100.0%", totalgainloss: "30%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 9/30/24)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
